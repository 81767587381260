












































































import { Component, Vue, Model, Emit, Prop, Watch } from 'vue-property-decorator'
import { AttendanceController } from '@/services/request.service'
import moment, { Moment } from 'moment'
import { calcBg, calcSymbol, getSchoolInfo } from '@/utils/utils'
import SvgIcon from '@/components/SvgIcon.vue'

@Component({
  components: {
    SvgIcon,
  },
})
export default class AttendanceDetail extends Vue {
  @Prop() private readonly data!: any
  @Prop() private readonly date!: any

  private moment = moment
  private calcBg = calcBg
  private calcSymbol = calcSymbol
  private schoolId = (getSchoolInfo() || {}).schoolId

  private get rows(): any {
    const preWeekDay = this.date.isoWeekday()
    let startDate = moment(this.date).subtract(preWeekDay, 'day')
    // let tmpData = {}
    // this.data.forEach(item => {
    //   this.$set(tmpData, moment(item.date).date(), item)
    // })
    const rows = Array.from({ length: 6 }).map((row, ridx) => {
      return Array.from({ length: 7 }).map((item, idx) => {
        const day = moment(startDate.add(1, 'day'))
        let status = 'noRecords'
        let comment = ''
        let leaveInfo = null
        let editAble = true
        if (day.isSame(this.date, 'month')) {
          let data = (this.data || {})[day.date()] || {}
          status = data.status || 'noRecords'
          comment = data.comment
          editAble = data.editable
          leaveInfo = data.leaveInfo
        }
        return {
          day: day.date(),
          time: day,
          timeString: day.format('YYYY-MM-DD'),
          type: day.isSame(this.date, 'month')
            ? 'cur'
            : day.isBefore(this.date, 'month')
            ? 'pre'
            : 'next',
          status,
          editAble,
          comment,
          leaveInfo,
        }
      })
    })
    return rows
  }

  private get currentSchoolYear(): any {
    return this.$store.state.currentSchoolYear
  }

  private get lastDisabled(): any {
    return (
      moment(this.date).subtract(1, 'month') <=
      moment(this.currentSchoolYear.startTime).startOf('day')
    )
  }

  private get nextDisabled(): any {
    return moment(this.date).add(1, 'month') >= moment(this.currentSchoolYear.endTime).endOf('day')
  }

  private prevWeek(): void {
    if (this.lastDisabled) return
    this.$emit('weekChange', { type: 'pre' })
  }

  private nextWeek(): void {
    if (this.nextDisabled) return
    this.$emit('weekChange', { type: 'next' })
  }
}


























































































































































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import { AttendanceController, DropDownController } from '@/services/request.service'
import moment, { Moment } from 'moment'
import StudentDetail from './components/StudentDetail.vue'
import { calcBg, calcSymbol, getSchoolInfo } from '@/utils/utils'
import AttendanceDetail from './components/AttendanceDetail.vue'

@Component({
  components: {
    StudentDetail,
    AttendanceDetail,
  },
})
export default class Attendance extends Vue {
  private startTime = moment().startOf('isoWeek')
  private endTime = moment().endOf('isoWeek')
  private data: Array<any> = []
  private loading: boolean = false
  private periods: Array<any> = []
  private date = moment().startOf('month')
  private calcBg = calcBg
  private calcSymbol = calcSymbol
  private type: string = ''
  private schoolId = (getSchoolInfo() || {}).schoolId
  private moment = moment
  private currentSchoolYear: any = {}

  private filter: any = {
    schoolYearId: undefined,
  }
  private schoolYearList: any = []

  private get locale(): string {
    return this.$store.state.locale
  }

  private get student(): any {
    return this.$store.state.currentStudent
  }

  private get studentId(): any {
    return this.$store.state.currentStudent?.studentId
      ? parseInt(this.$store.state.currentStudent.studentId, 10)
      : undefined
  }

  private get isDay(): boolean {
    // return this.$store.state.currentStudent?.campusType === '1231'
    return this.type === 'full_day'
  }

  private get lastDisabled(): any {
    return (
      moment(this.endTime).subtract(1, 'week') <=
      moment(this.currentSchoolYear.startTime).startOf('day')
    )
  }

  private get nextDisabled(): any {
    return (
      moment(this.startTime).add(1, 'week') >= moment(this.currentSchoolYear.endTime).endOf('day')
    )
  }

  @Watch('studentId', { deep: true, immediate: true })
  private onStudentChange(val): void {
    if (val) {
      AttendanceController.getAttendanceType(this.studentId)
        .then(res => {
          this.type = res.data.value
          this.getSchoolYearList()
        })
        .catch(err => console.log(err))
    }
  }

  private getSchoolYearList(): void {
    DropDownController.getSchoolYearRuleList().then(res => {
      this.schoolYearList = res.data
      this.filter.schoolYearId = this.schoolYearList[0].key
      this.currentSchoolYear = this.schoolYearList[0]
      this.getAttendance()
    })
  }

  private handleChangeSchoolYear(schoolYearId): void {
    const currentYear = this.schoolYearList.find(item => item.key === schoolYearId)
    this.currentSchoolYear = currentYear
    this.startTime = moment(currentYear.startTime).startOf('isoWeek')
    this.endTime = moment(currentYear.startTime).endOf('isoWeek')
    this.date = moment(currentYear.startTime).startOf('month')
    this.getAttendance()
  }

  private onDateChange(params): void {
    if (this.loading) return
    this.date =
      params.type === 'pre'
        ? moment(this.date).subtract(1, 'month')
        : moment(this.date).add(1, 'month')
    this.getAttendance()
  }

  private getAttendance(): void {
    this.data = []
    this.loading = true
    const isKind = this.student.campusType === '1230' || this.schoolId === 8
    !isKind
      ? this.isDay
        ? AttendanceController.getEleStudentStatistics(
            this.filter.schoolYearId,
            this.startTime.valueOf(),
            this.endTime.valueOf(),
            this.studentId
          )
            .then(res => {
              this.data =
                res.data?.map(item => ({ ...item, leaveInfo: item.leaveInfo || [] })) || []
              this.periods = res.data?.[0].classPeriods || []
            })
            .catch(err => {
              console.error(err)
            })
            .finally(() => (this.loading = false))
        : AttendanceController.getStudentStatistics(
            this.filter.schoolYearId,
            this.studentId,
            this.startTime.valueOf(),
            this.endTime.valueOf()
          )
            .then(res => {
              this.data = res.data.map(item => ({ ...item, leaveInfo: item.leaveInfo || [] }))
              this.periods = res.data[0].classPeriods
            })
            .catch(err => {
              console.error(err)
            })
            .finally(() => (this.loading = false))
      : AttendanceController.getKGStudentAttendance(this.studentId, this.date.valueOf())
          .then(res => {
            this.data = res.data
          })
          .catch(err => {
            console.error(err)
          })
          .finally(() => (this.loading = false))
  }

  private prevWeek(): void {
    const startTime = moment(this.startTime).subtract(1, 'week')
    const endTime = moment(this.endTime).subtract(1, 'week')
    if (endTime <= moment(this.currentSchoolYear.startTime).startOf('day')) return
    this.startTime = moment(startTime)
    this.endTime = moment(endTime)
    this.getAttendance()
  }

  private nextWeek(): void {
    const startTime = moment(this.startTime).add(1, 'week')
    const endTime = moment(this.endTime).add(1, 'week')
    if (startTime >= moment(this.currentSchoolYear.endTime).endOf('day')) return
    this.startTime = moment(startTime)
    this.endTime = moment(endTime)
    this.getAttendance()
  }

  private created(): void {}
}

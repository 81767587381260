var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"atten-student-detail"},[_c('div',{staticClass:"detail-time"},[_c('a-button',{staticClass:"arrow-btn",style:({ color: _vm.lastDisabled ? '#e8e8e8' : '#000' }),attrs:{"type":"link","icon":"left"},on:{"click":_vm.prevWeek}}),_c('span',{staticClass:"time"},[_vm._v(_vm._s(_vm.$d(_vm.date, 'month')))]),_c('a-button',{staticClass:"arrow-btn",style:({ color: _vm.nextDisabled ? '#e8e8e8' : '#000' }),attrs:{"type":"link","icon":"right"},on:{"click":_vm.nextWeek}})],1),_c('table',{staticClass:"attendance-table",attrs:{"width":"100%"}},[_c('thead',[_c('tr',_vm._l((7),function(weekday){return _c('th',{key:weekday,attrs:{"title":_vm.$t(("weekday." + weekday))}},[_c('span',[_vm._v(_vm._s(_vm.$t(("weekday." + weekday))))])])}),0)]),_c('tbody',_vm._l((_vm.rows),function(row,index){return _c('tr',{key:index},_vm._l((row),function(day){return _c('td',{key:day.timeString,style:({
            backgroundColor: _vm.calcBg(day.status),
            color:
              day.type === 'cur'
                ? !['noRecords', 'weekendHoliday'].includes(day.status)
                  ? '#fff'
                  : 'rgba(0, 0, 0, 0.45)'
                : '#e8e8e8',
          })},[_c('div',{staticStyle:{"min-height":"21px"}},[_vm._v(_vm._s(_vm.calcSymbol(day.status)))]),_c('a-tooltip',{attrs:{"title":day.comment || '',"getPopupContainer":function () { return _vm.$document.body; }}},[(day.comment)?_c('a-icon',{staticClass:"remark",attrs:{"theme":"filled","type":"message"}}):_vm._e()],1),(day.leaveInfo && day.leaveInfo.length)?_c('a-tooltip',{attrs:{"overlayStyle":{ maxWidth: '320px' }},scopedSlots:_vm._u([{key:"title",fn:function(){return _vm._l((day.leaveInfo),function(info){return _c('div',{key:info.startTime},[_c('div',[_c('span',[_vm._v(_vm._s(_vm.$t('leaveApproval.leaveTime'))+"：")]),_c('span',[_vm._v(_vm._s(_vm._f("doubleMoment")([info.startTime, info.endTime],'YYYY.MM.DD HH:mm')))])]),_c('div',[_c('span',[_vm._v(_vm._s(_vm.$t('leaveApproval.leaveReason'))+"：")]),_c('span',[_vm._v(_vm._s(info.reason))])])])})},proxy:true}],null,true)},[_c('svg-icon',{attrs:{"icon":"takeLeave","className":"take-leave-svg"}})],1):_vm._e(),_c('span',{staticClass:"day"},[_vm._v(_vm._s(day.day))])],1)}),0)}),0)]),_c('span',[_vm._v(_vm._s(_vm.schoolId !== 8 ? _vm.$t('attendance.detailTip1') : _vm.$t('attendance.detailTip3')))]),_c('span',[_vm._v(_vm._s(_vm.$t('attendance.detailTip2')))])])}
var staticRenderFns = []

export { render, staticRenderFns }